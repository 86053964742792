
export default function ACCESS_TOKEN() {
    const TOKEN = localStorage.getItem("accessToken");
    if(TOKEN !== null){
      return `Bearer ${TOKEN}`
    }else{
      return null
    }
  }

// const ACCESS_TOKEN = localStorage.getItem("accessToken");

// const accessToken = `Bearer ${ACCESS_TOKEN}`;
// export default accessToken;
