import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  START_LOADER,
  STOP_LOADER
} from "./actionTypes";
import {
  startLoadr,
  stopLoadr,
} from "./actions";

function* startLoader() {
    yield put(startLoadr(true));
}
function* stopLoader() {
  yield put(stopLoadr(false));
}
function* playersSaga() {
  yield takeEvery(START_LOADER, startLoader);
  yield takeEvery(STOP_LOADER, stopLoader);
}

export default playersSaga;
