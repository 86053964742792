import React from 'react'

export default function Loader() {
    const loader = {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
        background: `url(/loader.gif) 50% 50% no-repeat #c5c5c580`,
        opacity: 1,
    }
    return (
        <div style={loader} />
    )
}
