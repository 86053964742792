import {
  START_LOADER,
  STOP_LOADER,
} from "./actionTypes"

export const startLoadr = (payload) => ({
  type: START_LOADER,
  payload: payload
})
export const stopLoadr = (payload) => ({
  type: STOP_LOADER,
  payload: payload
})