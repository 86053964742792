import {
  START_LOADER,
  STOP_LOADER
} from "./actionTypes";

const INIT_STATE = {
  loader: [],
};

const playersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case STOP_LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    default:
      return state;
  }
};

export default playersReducer;
