import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
// import MetisMenu from "metismenujs";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
const SidebarContent = props => {
  const history = useHistory(); // Use useHistory for navigation
  const ref = useRef();
  const [Role, setRole] = useState("")
  const [id, setId] = useState("")
  const [desination, setDesination] = useState("")


  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      // new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    var storedData = localStorage.getItem("authUser");

    if (storedData) {
      // localStorage mein data mojud hai
      var parsedData = JSON.parse(storedData);
      setRole(parsedData.is_admin)
      setId(parsedData.id)
      setDesination(parsedData.desination)
        ;
    } else {
      // localStorage mein data nahi mojud hai ya null hai
      console.log("No data in localstorage.");
    }

  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  // const [recognition, setRecognition] = useState(null);
  // const [command, setCommand] = useState('');

  // useEffect(() => {
  //   const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  //   if (!SpeechRecognition) {
  //     console.error('Speech Recognition not supported in this browser.');
  //     return;
  //   }

  //   const recognitionInstance = new SpeechRecognition();
  //   recognitionInstance.continuous = false; // Stop after one result
  //   recognitionInstance.interimResults = false; // Only final results

  //   recognitionInstance.onresult = (event) => {
  //     const command = event.results[0][0].transcript;
  //     setCommand(command);
  //     console.log('command:', command);
  //     if (command.includes('navigate to') || command.includes('go to')) {
  //       if(command.includes('tournaments')|| command.includes('tournaments')){
  //         window.location.href='/Tournaments'

  //       }
  //       else if (command.includes('t dashboard')|| command.includes('tea dashboard')){
  //         window.location.href='/Teedashboard'
  //       }
  //       else if (command.includes('approach dashboard')|| command.includes('approach dashboard')){
  //         window.location.href='/Approachdashboard'
  //       }
  //       else if (command.includes('shot game dashboard')|| command.includes('short game dashboard')){
  //         window.location.href='/ShortGameDashboard'
  //       }
  //       else if (command.includes('putting dashboard')|| command.includes('puttingdashboard')){
  //         window.location.href='/Puttingdashboard'
  //       }
  //       else if (command.includes('t visuals')|| command.includes('tea visuals')){
  //         window.location.href='/Teevisuals'
  //       }
  //       else if (command.includes('Short game visuals')|| command.includes('Short game visuals')){
  //         window.location.href='/Shortgamevisuals'
  //       }
  //       else if (command.includes('putting visuals')|| command.includes('putting visuals')){
  //         window.location.href='/puttingVisual'
  //       }

  //       else if (command.includes('approach visuals')|| command.includes('approach visuals')){
  //         window.location.href='/approachvisual'
  //       }



  //     }
  //     recognitionInstance.stop(); // Stop recognition after receiving a command
  //   };

  //   // recognitionInstance.onend = () => {
  //   //   setTimeout(() => {
  //   //     recognitionInstance.start(); // Restart recognition after 5 seconds
  //   //   }, 5000);
  //   // };

  //   setRecognition(recognitionInstance);

  //   return () => {
  //     recognitionInstance.stop(); // Cleanup on unmount
  //   };
  // }, []);

  // useEffect(() => {
  //   if (recognition) {
  //     recognition.start();
  //   }
  // }, [recognition]);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <div className="d-flex ">
              <li className="menu-title">{props.t("Menu")} </li>
              {/* <li className="bx bx-microphone" style={{fontSize:"20px", color:"white"}}></li>  */}
            </div>
            <li>
              <Link to="/dashboard" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Last Rounds")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Apps")}</li>

            {/* <li>
              <Link to="/player_types" >
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Player Types")}</span>
              </Link>
            </li> */}
            {Role == 1 ?
              <li>
                <Link to="/players" >
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Players")}</span>
                </Link>
              </li> : ""}

            {Role == 1 ?
              <li>
                <Link to="/Tour" >
                  <i className='bx bxs-store-alt'></i>
                  <span>{props.t("Tour")}</span>
                </Link>
              </li> : ""}

            {Role == 1 && id == 1 ?
              <li>
                <Link to="/SubAdmin" >
                  <i className='bx bx-user'></i>
                  <span>{props.t("SubAdmin")}</span>
                </Link>
              </li> :
              ""
            }

            {/* <li>
              <Link to="/Rounds" >
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Rounds")}</span>
              </Link>
            </li> */}
            {Role == 1 ?
              <li>
                <Link to="/Tournaments" >
                  <i className='bx bxs-user-account'></i>
                  <span>{props.t("Tournaments")}</span>
                </Link>
              </li> : ""}

            {desination != "Coach" ? 
              <li>
                <Link to="/EnterRound" >
                  <i className='bx bxs-adjust-alt'></i>
                  <span>{props.t("Enter Round")}</span>
                </Link>
              </li> :""}

          
              {desination != "Coach" ? 
              <li>
                <Link to="/ScoreDetail" >
                  <i className='bx bxs-adjust-alt'></i>
                  <span>{props.t("Score Detail")}</span>
                </Link>
              </li> :""}
            {/* {Role == 1 ? */}

            {Role == 1 || Role == 0  && desination === "Player+" || desination === "Coach" ?
            
           
            <li>
              <Link to="/Generaldashboard" >
                <i className='bx bx-home-circle'></i>
                <span>{props.t("General Dashboard")}</span>
              </Link>
            </li>:""} 
           

            {Role == 1 ?
      
              <li>
                <Link to="/Teedashboard" >
                  <i className='bx bx-home-circle'></i>
                  <span>{props.t("Tee Dashboard")}</span>
                </Link>
              </li> : ""}
            {/* {Role == 1 ? */}


            {Role == 1 || Role == 0  && desination === "Player+" || desination === "Coach" ?
            <li>
              <Link to="/Teevisuals" >
                <i className='bx bx-home-circle'></i>
                <span>{props.t("Tee Visuals")}</span>
              </Link>
            </li>:""}
            {/* : ""} */}

            {Role == 1 ?
              <li>
                <Link to="/Approachdashboard" >
                  <i className='bx bx-home-circle'></i>
                  <span>{props.t("Approach Dashboard")}</span>
                </Link>
              </li> : ""}
            {/* {Role == 1 ? */}
            {Role == 1 || Role == 0  && desination === "Player+" || desination === "Coach" ?
            <li>
              <Link to="/approachvisual" >
                <i className='bx bx-home-circle'></i>
                <span>{props.t("Approach Visuals")}</span>
              </Link>
            </li>:""}
            {/* // : ""} */}

            {Role == 1 ?
              <li>
                <Link to="/ShortGameDashboard" >
                  <i className='bx bx-home-circle'></i>
                  <span>{props.t("ShortGame Dashboard")}</span>
                </Link>
              </li> : ""}
            {/* {Role == 1 ? */}
            {Role == 1 || Role == 0  && desination === "Player+" || desination === "Coach" ?
            <li>
              <Link to="/Shortgamevisuals" >
                <i className='bx bx-home-circle'></i>
                <span>{props.t("Shortgame Visuals")}</span>
              </Link>
            </li>:""}
            {/* // : ""} */}

            {Role == 1 ?
              <li>
                <Link to="/Puttingdashboard" >
                  <i className='bx bx-home-circle'></i>
                  <span>{props.t("Putting Dashboard")}</span>
                </Link>
              </li> : ""}

            {/* {Role == 1 ? */}
            {Role == 1 || Role == 0  && desination === "Player+" || desination === "Coach" ?

            <li>
              <Link to="/puttingVisual" >
                <i className='bx bx-home-circle'></i>
                <span>{props.t("Putting Visuals")}</span>
              </Link>
            </li>:""}
            <li>
            <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 " />
            <span>{props.t("Logout")}</span> </Link></li>
           



            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Default")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Saas")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Crypto")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog")}</Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                    {props.t("Jobs")}
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Apps")}</li>

            <li>
              <Link to="#" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li>

            <li>
              <Link to="#" >
                <i className="bx bx-chat"></i>
                <span>{props.t("Chat")}</span>
              </Link>
            </li>
            <li>
              <Link to="#" >
                <i className="bx bx-file"></i>
                <span>{props.t("File Manager")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Ecommerce")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Products")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Product Detail")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Cart")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Checkout")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Shops")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Add Product")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>{props.t("Crypto")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Wallet")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Buy/Sell")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Exchange")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Lending")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("KYC Application")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("ICO Landing")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Read Email")} </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">                    
                    <span key="t-email-templates">{props.t("Templates")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="#">
                        {props.t("Basic Action")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {props.t("Alert Email")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {props.t("Billing Email")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Invoices")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Invoice List")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Invoice Detail")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Projects")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Projects Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Projects List")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Project Overview")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Create New")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>{props.t("Tasks")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Task List")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Create Task")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("User Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Profile")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-detail" />
                <span>{props.t("Blog")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Blog List")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog Details")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#">
                <span className="badge rounded-pill bg-success float-end" key="t-new">New</span>
                <i className="bx bx-briefcase-alt"></i>
                <span key="t-jobs">{props.t("Jobs")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="#">{props.t("Job List")}</Link></li>
                <li><Link to="#">{props.t("Job Grid")}</Link></li>
                <li><Link to="#">{props.t("Apply Job")}</Link></li>
                <li><Link to="#">{props.t("Job Details")}</Link></li>
                <li><Link to="#">{props.t("Jobs Categories")}</Link></li>
                <li>
                  <Link to="/#" className="has-arrow">{props.t("Candidate")}</Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li><Link to="#">{props.t("List")}</Link></li>
                    <li><Link to="#">{props.t("Overview")}</Link></li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>                
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Login 2")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Register 2")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Recover Password 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Lock Screen 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Confirm Mail 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Two Step Verification 2")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>{props.t("Utility")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Components")}</li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-tone" />
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("OffCanvas")}</Link>
                </li>

                <li>
                  <Link to="#">
                    {props.t("Range Slider")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Session Timeout")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Progress Bars")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Placeholders")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Typography")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Toasts")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("General")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Colors")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Rating")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Notifications")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="badge rounded-pill bg-success float-end">
                      {props.t("New")}
                    </span>
                    {props.t("Utilities")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Layouts")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Form Validation")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Xeditable")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Repeater")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Form Mask")}</Link>
                </li>                
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Editable Table")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Drag & Drop Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Apex charts")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("E Chart")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Chartjs Chart")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Chartist Chart")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Knob Charts")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>

                <li>
                  <Link to="#">{props.t("Re Chart")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-aperture"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Vector Maps")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Leaflet Maps")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
